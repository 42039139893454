import Cookies from 'js-cookie'

export function setToken(value) {
    return Cookies.set("token", value, { expires: 7 });
}
export function getToken() {
    return Cookies.get("token");
}
export function removeToken() {
    return Cookies.remove("token");
}
export function setUrl(value) {
    return window.sessionStorage.setItem("baseUrl", value)
}
export function getUrl() {
    return window.sessionStorage.getItem("baseUrl")
}
export function setNotice(value) {
    return Cookies.set("notice", value, { expires: 90 });
}
export function getNotice() {
    return Cookies.get("notice");
}
/**
 * @description 生成唯一 uuid
 * @return string
 */
export function generateUUID() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
}