import request from '@/utils/request'

export function fetchArticleList(params) {
    return request({
        url: '/member/v1/article/',
        method: 'get',
        params: params
    })
}
export function getArticleByUserId(params) {
    return request({
        url: '/member/v1/article/selectArticleByUserId',
        method: 'get',
        params: params
    })
}
export function deleteMyArticle(id) {
    return request({
        url: '/member/v1/article/',
        method: 'delete',
        params: {
            id: id
        }
    })
}
export function getMyArticleInfo(id) {
    return request({
        url: '/member/v1/article/selectMyArticleInfo',
        method: 'get',
        params: {
            id: id
        }
    })
}
export function readMarkdownFile(data) {
    return request({
        url: '/member/v1/article/readMarkdownFile',
        method: 'post',
        data
    })
}
export function insertArticle(data) {
    return request({
        url: '/member/v1/article/',
        method: 'post',
        data
    })
}
export function updateArticle(data) {
    return request({
        url: '/member/v1/article/',
        method: 'put',
        data
    })
}
export function searchArticle(params) {
    return request({
        url: '/member/v1/article/search',
        method: 'get',
        params: params
    })
}

export function articleInfo(id) {
    return request({
        url: '/member/v1/article/info',
        method: 'get',
        params: {
            id: id
        }
    })
}
export function selectUserInfoByArticleId(id) {
    return request({
        url: '/member/v1/article/selectUserInfoByArticleId',
        method: 'get',
        params: {
            id: id
        }
    })
}
export function checkCode(code) {
    return request({
        url: '/member/v1/article/checkCode',
        method: 'get',
        params: {
            code: code
        }
    })
}
export function articleLike(id) {
    return request({
        url: '/member/v1/article/like',
        method: 'get',
        params: {
            articleId: id
        }
    })
}
export function archive() {
    return request({
        url: '/member/v1/article/archive',
        method: 'get',
    })
}

export function fetchTagList() {
    return request({
        url: '/member/v1/tag/',
        method: 'get',

    })
}

// export function featchHomeData() {
//     return request({
//         url: '/member/v1/',
//         method: 'get',
//     })
// }
export function getHot(type) {
    return request({
        url: '/member/v1/hot',
        method: 'get',
        params: {
            type: type
        }
    })
}
export function report() {
    return request({
        url: '/member/v1/report',
        method: 'get',
    })
}
export function getWebSiteInfo() {
    // return request({
    //     url: '/member/v1/webSiteInfo',
    //     method: 'get',
    // })
}

export function emailLogin(data) {
    return request({
        url: '/auth/oauth/token',
        method: 'post',
        params: data
    })
}
export function forgetPassword(data) {
    return request({
        url: '/member/v1/user/forgetPassword',
        method: 'post',
        data
    })
}
export function logout() {
    return request({
        url: '/auth/oauth/logout',
        method: 'delete',
    });
}
export function openAuthUrl(source) {
    return request({
        url: '/auth/oauth/' + source,
        method: 'get'
    })
}
export function wxIsLogin(loginCode) {
    return request({
        url: '/oauth/wechat/is_login',
        method: 'get',
        params: {
            loginCode: loginCode
        }
    })
}
export function getWechatLoginCode() {
    return request({
        url: '/oauth/wechatLoginCode',
        method: 'get',

    })
}
export function sendEmailCode(email) {
    return request({
        url: '/member/v1/user/sendEmailCode',
        method: 'get',
        params: {
            email: email
        }
    })
}
export function emailRegister(data) {
    return request({
        url: '/member/v1/user/register',
        method: 'post',
        data
    })
}
export function updateUserInfo(data) {
    return request({
        url: '/member/v1/user/setting/basic',
        method: 'put',
        data
    })
}
// export function getUserInfo(userId) {
//     return request({
//         url: '/member/v1/user/info',
//         method: 'get',
//         params: {
//             userId: userId
//         }
//     })
// }
export function queryAuthorInfo(userId) {
    return request({
        url: '/member/v1/user/author',
        method: 'get',
        params: {
            userId: userId
        }
    })
}
export function selectUserInfo() {
    return request({
        url: '/member/v1/user/info',
        method: 'get'
    })
}
export function getUserCount(id) {
    return request({
        url: '/member/v1/user/getUserCount',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function upload(data) {
    return request({
        url: '/member/v1/storage/uploadFile',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/articles-data' },
        data
    })
}
export function featchCategory(dictType) {
    return request({
        url: '/member/v1/category/?dictType=' + dictType,
        method: 'get'
    })
}

export function addFeedback(data) {
    return request({
        url: '/member/v1/feedback/',
        method: 'post',
        data
    })
}
export function getCollect() {
    return request({
        url: '/member/v1/collect/',
        method: 'get'
    })
}
export function collect(id) {
    return request({
        url: '/member/v1/collect/collect',
        method: 'get',
        params: {
            articleId: id
        }
    })
}

export function bannerList() {
    return request({
        url: '/member/v1/carousel/',
        method: 'get'
    })
}

export function cancelCollect(id) {
    return request({
        url: '/member/v1/collect/',
        method: 'delete',
        params: {
            articleId: id
        }
    })
}

export function followedUser(userId) {
    return request({
        url: '/member/v1/followed/insertFollowed',
        method: 'post',
        params: {
            userId: userId
        }
    })
}
export function deleteFollowedUser(userId) {
    return request({
        url: '/member/v1/followed/deleteFollowed',
        method: 'delete',
        params: {
            userId: userId
        }
    })
}

