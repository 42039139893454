<template>
  <div id="app">
    <!-- 头部 -->
    <Header :userInfo=userInfo></Header>
    <!-- 侧边导航栏 -->
    <SideNavBar></SideNavBar>
    <Loading></Loading>
    <Notice></Notice>
    <router-view :key="$route.fullPath" />

    <!-- 登录模态框 -->
    <Login></Login>
    <SearchModle></SearchModle>
    <!-- 侧边栏 -->
    <Sidebar></Sidebar>

    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import SideNavBar from "@/components/layout/SideNavBar.vue";
import Sidebar from '@/components/layout/Sidebar.vue'
import Loading from '@/components/loading/loading.vue'
import Login from '@/components/model/Login.vue'
import SearchModle from '@/components/model/Search.vue'
import Notice from '@/view/notice/topNotice.vue'
import { report, getWebSiteInfo, selectUserInfo } from '@/api'
import { setSkin, getSkin, getToken, setToken } from '@/utils/cookieUtil'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Sidebar,
    Login,
    SideNavBar,
    SearchModle,
    Loading,
    Notice,
  },
  data() {
    return {
      userInfo: this.$store.state.userInfo,
    }
  },
  created() {
    this.getUserInfo()
    this.initWebSiteInfo()
    var that = this

    //监听整个页面的 copy 事件
    document.addEventListener('copy', function (e) {
      let clipboardData = e.clipboardData || window.clipboardData;
      if (!clipboardData) return;
      let text = window.getSelection().toString();
      if (text) {
        e.preventDefault();
        clipboardData.setData('text/plain', text)
        that.$toast.success("复制成功,转载请务必保留原文链接!")
      }
    })

  },

  methods: {
    initWebSiteInfo() {
      this.$store.commit("setWebSiteInfo", {logo: 'http://img.xmbiao.cn/xmb/2024/01/17/fb0cdf5f-17c6-4bfe-8020-7eb2bfeecf29.jpeg',  qqNumber: '1377814408', email: '15224991893@163.com', qqGroup: '89465561', wechat: '15224991893'})
      // getWebSiteInfo().then(res => {
      //   this.$store.commit("setWebSiteInfo", res.data)
      //   this.$store.state.siteAccess = res.extra.siteAccess
      //   this.$store.state.visitorAccess = res.extra.visitorAccess
      // })
    },
    getUserInfo() {
      let flag = window.location.href.indexOf("token") != -1
      if (flag) {
        let token = window.location.href.split("token=")[1]
        setToken(token)
      }

      // 从cookie中获取token
      let token = getToken()
      if (token != null) {
        selectUserInfo().then(res => {
          if(res.status == 2000) {
            this.userInfo = res.data
            this.$store.commit("setUserInfo", res.data)
          }
        })
      }
    },
  },

  mounted() {
    let theme = sessionStorage.getItem("theme")
    if (theme == null) {
      theme = "light"
    }
    document.documentElement.dataset.theme = theme
  },

}
</script>

<style>
/* @import "./static/css/body.css"; */
</style>
